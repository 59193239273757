<template>
    <div>
		<loading-overlay :active="isLoading" :is-full-page="true" loader="bars" />
		<CCol sm="12" class="mt-2">
			<CRow>
                <CCol sm="12" lg="4">
                    <CSelect
                        :label="$t('label.ubication')"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                        :options="optionLocationList"
                        v-model="$v.filtroUbicacion.$model"
                        :is-valid="hasError($v.filtroUbicacion)"
                        :invalid-feedback="errorMessage($v.filtroUbicacion)"    
						@change="getMachineData"
                    />
                </CCol>
                <CCol sm="12" lg="5">
                    <div class="position-relative">
                        <div class="form-group form-row" rol="group">
                            <label class="required col-form-label col-sm-12 col-lg-3 text-right" for="tpGrua">{{$t('label.machine')}}</label>
                            <div class="col-sm-12 col-lg-8 input-group">
                                <v-select
                                    :placeholder="$t('label.select')"
                                    :class="filtroMaquina ? 'select-client--correct Vselect-adjust' : (verifySelect ? 'select-client--error Vselect-adjust' : 'Vselect-adjust')"
                                    v-model="$v.filtroMaquina.$model"
                                    :options="optionMachineryList" 
                                    :reduce="option => option.value" 
                                    :getOptionLabel="option => option.title"
                                >
                                    <template #no-options="{ }">
                                        {{$t('label.noResultsFound')}}
                                    </template>
                                    <template #option="{ Json, title }">
                                        {{ title }}
                                        <br />
                                        <cite v-if="title!=$t('label.select')">{{ Json.TpMachineName }} - {{ Json.MachineConditionName }} </cite>
                                    </template>
                                </v-select>
                            	<div class="input-group-append">
                                    <div class="d-flex align-items-start">
                                        <CButton
                                            color="add"
                                            size="sm"
                                            class="d-flex align-items-center"
                                            @click.stop="toggleMaquinaAdd"
                                            v-c-tooltip="{
                                                content: $t('label.add')+' '+$t('label.machine'),
                                                placement: 'top-end'
                                            }"
                                            v-if="!filtroMaquina"
                                        >
                                            <CIcon name="cil-playlist-add"/>
                                        </CButton>
                                        <CButton
                                            v-else
                                            color="watch"
                                            size="sm"
                                            class="d-flex align-items-center"
                                            @click.stop="toggleMaquinaAdd"
                                            v-c-tooltip="{
                                                content: $t('label.toView')+' '+$t('label.machine'),
                                                placement: 'top-end'
                                            }"
                                        >
                                            <CIcon name="eye"/>
                                        </CButton>
                                    </div>
                                </div>
                                <div class="col-sm-12 px-0" v-if="$v.filtroMaquina.$error || (filtroMaquina == '' && verifySelect == true)">
                                    <div class="text-invalid-feedback">
                                        {{$t('label.required')}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CCol>
				<CCol class="col-sm-12 col-lg-auto col-xl-auto center-field pl-0" style="text-align: right;">
                    <CButton
                        color="add"
                        size="sm"
                        class="mr-1"
                        :disabled="isSubmit"
                        @click="AssignMachinery"
                        v-c-tooltip="{
							content: $t('label.add'),
							placement: 'top-end'
                        }"
                    >
                        <CIcon name="checkAlt"/>
                    </CButton>
                    <CButton
                        color="wipe"
                        class="justify-content-end"
                        size="sm"
                        v-c-tooltip="{
                            content: $t('label.clearFilters'),
                            placement: 'top-end'
                        }"
                        @click="resetMachine(true, false)" 
                    >
                        <CIcon name="cil-brush-alt" />
                    </CButton>
                </CCol>       
            </CRow>
		</CCol>
		<CCol sm="12">
            <dataTableExtended
            	class="align-center-row-datatable"
                :items="formatedItems"
                :fields="fields"
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                sorter
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="5"
                pagination
                column-filter
                hover    
            >
                <template #FormatedTurnStatus="{item}">
                    <td class="text-center">
                        <CBadge :color="StatusColor(item)">
                            {{item.FormatedTurnStatus}}
                        </CBadge>
                    </td>
                </template>
                <template #buttons="{item}">
                    <td class="text-center">
                        <CButton
                            color="watch"
                            size="sm"
                            class="mr-1"
                            @click="AssignWorkTime(item)"
                            v-c-tooltip="{
                                content: $t('label.toView')+' '+$t('label.HourRangesMachineWork'),
                                placement: 'top-end'
                            }"
                         >
                            <CIcon name="calendaryChecked"/>
                        </CButton>
                        <CButton
                            color="edit"
                            size="sm"
                            class="mr-1"
                            v-c-tooltip="{
                                content: $t('label.edit')+' '+$t('label.machine'),
                                placement: 'top-end'
                            }"
                            @click="editMachine(item)"
                        >
                            <CIcon name="pencil"/>
                        </CButton>
                        <CButton
                            color="wipe"
                            size="sm"
                            class="mr-1"
                            v-c-tooltip="{
                                content: $t('label.InactivateMachineVisit'),
                                placement: 'top-end'
                            }"
                            @click="DeleteMachine(item)"
                        >
                            <CIcon name="cil-trash" />
                        </CButton>
                    </td>
                </template>
            </dataTableExtended>
        </CCol>
        <WorkingTimeMachine :modal="ModalWorkingTime" :machine="machine" @set-list="resetMachine"></WorkingTimeMachine>
		<appMachinesGruas @set-list="resetMachine" :modalCraneMachine="modalCraneMachine"></appMachinesGruas>
	</div>
</template>

<script>
    import { mapState } from 'vuex';
    import GeneralMixin from '@/_mixins/general';
	import {tableTextTranslatedHelpers, alertPropertiesHelpers} from '@/_helpers/funciones';
    import WorkingTimeMachine from './modal-working-time-machine';
	import appMachinesGruas from '@/pages/planificacion-estiba/modals/app-machinesgruas-crud';
	import MachineMetalScrapValidations from '@/_validations/planificacion-estiba/MachineMetalScrapValidations';

    //data
    function data() {
        return {
            ModalWorkingTime: false,
            modalCraneMachine: false,
            isLoading:false,
            verifySelect: false,
            filtroMaquina:'',
            filtroUbicacion:'',
            machine: {},
            LocationList:[],
			optionMachinery: [],
            MachineryList:[],
            isSubmit: false,
        }
    }

	//methods
	function getList() {
		this.isLoading = true;
		let requests =  
		[
			this.$http.ejecutar("GET", "StowageUbication-list"),
			this.$http.ejecutar("GET", "VisitMachine-list", {VisitId: this.VisitId}),
		];

		Promise.all(requests)
			.then((responses) => {
				this.LocationList = responses[0].data.data? responses[0].data.data : [];
				this.MachineryList = responses[1].data.data? responses[1].data.data : [];
				this.isLoading = false;
			})
			.catch((err) => {
				this.isLoading = false;
				this.$notify({
					group: "container",
					title: "¡Error!",
					text: err,
					type: "error",
				});
			})
	}

	function AssignMachinery(){
        try {
            this.isSubmit = true;
			this.isLoading = true;
            this.verifySelect = true;
            this.$v.$touch();
            if (this.$v.filtroMaquina.$error || this.$v.filtroUbicacion.$error) {
                throw this.$t('label.errorsPleaseCheck');
            }

            let link = 'VisitMachine-insert';
            let json = 'VisitMachineJson';

            let values = {
                VisitId: this.VisitId, 
				VisitResourceId: '',
                StowageUbicationId: this.filtroUbicacion,
                MachineId:  this.filtroMaquina,
            }
            this.$http.ejecutar('POST', link, values, { root: json })
                .then(response => {
					let information = response.data.data;
					this.$notify({
                        group: 'container',
                        title: this.$t('label.success'),
                        text: information[0].Response,
                        type: "success"
                    })
					this.resetMachine(true, true);
					this.isSubmit = false;
                }).catch( err => {
                    this.isSubmit = false;
					this.isLoading = false;
					this.$notify({
                        group: 'container',
                        title: this.$t('label.success'),
                        text: err,
                        type: "success"
                    })
                });
        } catch (error) {
            this.isSubmit = false;
			this.isLoading = false;
            this.$notify({
                group: 'container',
                type: 'error',
                title: 'ERROR',
                text: error,
            });
        }
    }

	function DeleteMachine(item){
        let nombre = '';
        let link = 'VisitMachineAll-update';
        let json = 'VisitMachineJson';

        nombre = item.MachineName;

        let values = {
            VisitId: item.VisitId, 
            MachineId: item.MachineId,
            StowageUbicationId: item.StowageUbicationId,
            Status:0
        }

        if(nombre !== ''){
            this.$swal.fire(
                alertPropertiesHelpers(this, {
                    text: this.$t('label.changeStatusQuestion')+'?'
                })
               ).then((result) => {
                if (result.isConfirmed) {
					this.isLoading = true;
                    this.$http.ejecutar('PUT', link, values, { root: json })
                    .then(response => {
						let information = response.data.data;
						this.resetMachine(true, true);
						this.$notify({
							group: 'container',
							title: this.$t('label.success'),
							text: information[0].Response,
							type: "success"
						})
                    }).catch( err => {
						this.isSubmit = false;
						this.isLoading = false;
                        this.$notify({
							group: 'container',
							type: 'error',
							title: 'ERROR',
							text: err,
						});
                    });
                }
            })
        }
    }

	async function getMachineData(event) {
        this.filtroUbicacion = event.target.value;
        this.filtroMaquina = '';
        if (this.filtroUbicacion) {
			this.isLoading = true;
			let values = {
				MachineJson:[
					{
						CompanyBranchId: this.branch.CompanyBranchId,
						VisitId: this.VisitId,
						StowageUbicationId: this.filtroUbicacion
					}
				]
			}
			await this.$http.ejecutar('GET', 'VisitMachineFree-list', { MachineJson:values })
			.then(response => {
				this.optionMachinery = response.data.data;
				this.isLoading = false;
			}).catch( err => {
				this.isLoading = false;
				this.$notify({
					group: 'container',
					title: '¡Error!',
					text: err,
					type: "error"
				});
			});
		}else{
			this.optionMachinery = [];
		}
        
    }

	function toggleMaquinaAdd(){
        this.$store.state.planificacionestiba.idState = this.filtroMaquina ? this.filtroMaquina : 0;
        this.modalCraneMachine = true;
    }

    function AssignWorkTime(item){
        this.machine = item;
        this.ModalWorkingTime = true;
    }

	function editMachine(item){
        this.$store.state.planificacionestiba.idState = item.MachineId;
        this.modalCraneMachine = true; 
    }

	function resetMachine(filtre, table){
        this.ModalWorkingTime = false;
        this.modalCraneMachine = false;
        if (filtre) {
            this.optionMachinery = [];
            this.filtroUbicacion = '';
            this.verifySelect = false;
            this.filtroMaquina = '';
            this.$v.$reset();
        }
        if (table) {
            this.getList();
        }
    }

	function StatusColor(item){
        if(item.TotalHour === '00:00'){
            return 'secondary';
        }else{
            return '';
        }
    }

	//computed
	function fields(){
        return [
            { key: 'Nro', label: '#',_style: 'width:2%; text-align:center;', filter:false },
            { key: 'MachineName',label: this.$t('label.machine'), _style:'width:15%',  _classes: 'text-center' },
            { key: 'TpMachineName',label: this.$t('label.type'), _style:'width:15%',  _classes: 'text-center' },
            { key: 'StowageUbicationName',label: this.$t('label.ubication'), _style:'width:12%',  _classes: 'text-center' },
            { key: 'MachineConditionName',label: this.$t('label.condition'), _style:'width:20%',  _classes: 'text-center' },
            { key: 'TotalHour',label: this.$t('label.HoursWorked'), _style:'width:12%', _classes: 'text-center' },
            { key: 'FormatedTurnStatus', label: this.$t('label.status'), _style:'width:20%; text-align:center;' },
            { 
                key: 'buttons',
                label: '', 
                _style: 'min-width: 135px; width: 1%', 
                sorter: false, 
                filter: false
            }
        ]
    }

	function formatedItems() {
        let _lang = this.$i18n.locale;
        return this.MachineryList.map((item) => {
			return {
				...item,
                FormatedTurnStatus: _lang=='en' ? item.TurnStatusEn : item.TurnStatusEs,
				_classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
				FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
			};
		});
    }

	function optionLocationList(){
        if(this.LocationList.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            let chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.LocationList.map(function(e){
                if(e.FgActStowageUbication){
                    chart.push({
                        value: e.StowageUbicationId, 
                        label: e.StowageUbicationName,
                    })
                }
            })
            return chart;
        }
    }

	function optionMachineryList() {
        if(this.optionMachinery.length === 0){
            return [{
                title: this.$t('label.select'),
                value: '',
                Json: {
                    TpMachineName: '',
                    MachineConditionName: ''
                },
            }];
        }else{
            let chart = [{
                title: this.$t('label.select'),
                value: '',
                Json: {
                    TpMachineName: '',
                    MachineConditionName: ''
                },
            }];
            let datos = this.optionMachinery;
            for (let index = 0; index < datos.length; index++) {
                let element = datos[index];
                chart.push({
                    title: element.MachineName,
                    value: element.MachineId,
                    Json: {
                        TpMachineName: element.TpMachineName,
                        MachineConditionName: element.MachineConditionName
                    },
                })
            }
            return chart;
        }
    }

	function tableText(){
        return tableTextTranslatedHelpers(this);
    }

    export default {
        name: 'app-maquinas',
        data,
        mixins: [GeneralMixin],
        validations: MachineMetalScrapValidations,
        methods: {
			getList,
			resetMachine,
			toggleMaquinaAdd,
            AssignWorkTime,
			editMachine,
			AssignMachinery,
			DeleteMachine,
			getMachineData,
			StatusColor,
        },
        computed: {
			optionLocationList,
			optionMachineryList,
			formatedItems,
			fields,
			tableText,
            ...mapState({
				branch: state => state.auth.branch,
				dropItemReport: state => state.visitas.dropItemReport,
				VisitId: state => state.visitas.VisitId,
            })
        },
        components: {
            WorkingTimeMachine,
			appMachinesGruas,
        },
        watch:{
			dropItemReport: function (val) {
				if (val==37&&this.VisitId != '') {
					this.resetMachine(true, true);
				}
			}
        }
    } 
</script>
<style lang="scss">
.Vselect-adjust{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin-bottom: 0;
}
.select-client--correct {
    .vs__dropdown-toggle {
      border-color: #2eb85c !important;
    }
  }
.select-client--error {
    .vs__dropdown-toggle {
      border-color: #e55353 !important;
    }
}
</style>